import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { allTypeManager } from './redux/services/meetingsync';
import PrivateRoute from './components/PrivateRoute';
// layouts
const DashboardLayout = lazy(() => import('./layouts/dashboard'));
const SimpleLayout = lazy(() => import('./layouts/simple'));
//
const JobPage = lazy(() => import('./pages/JobPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const ResetPassword = lazy(() => import('./pages/ResetPasswordPage'));
const CompleteReset = lazy(() => import('./pages/CompleteResetPage'));
const SubscriptionPage = lazy(() => import('./pages/SubscriptionPage'));
const Page404 = lazy(() => import('./pages/Page404'));
const Page404WS = lazy(() => import('./pages/Page404WS'));
const DashboardAppPage = lazy(() => import('./pages/DashboardAppPage'));
const SettingsPage = lazy(() => import('./pages/SettingPage'));
const PaymentPage = lazy(() => import('./pages/PaymentPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const ManageUsersPage = lazy(() => import('./pages/ManageUsersPage'));
const AdminSettingPage = lazy(() => import('./pages/AdminSettingPage'));
const PaymentTransactionsAdmin = lazy(() => import('./pages/PaymentTransactionsAdmin'));
const ManagePackagesPage = lazy(() => import('./pages/ManagePackagesPage'));
const ManageWorkspacePage = lazy(() => import('./pages/ManageWorkspacePage'));
const RegisterOwnerPage = lazy(() => import('./pages/RegisterOwnerPage'));
const LoadStripeContext = lazy(() => import('./components/StripeWrapper'));
const AuthCallbackPage = lazy(() => import('./pages/AuthCallbackPage'));
const AuthCallbackZoomPage = lazy(() => import('./pages/AuthCallbackZoomPage'));
const ManageConnectionsPage = lazy(() => import('./pages/ManageConnectionsPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/privacyPolicy'));
const TermsAndCondition = lazy(() => import('./pages/TermsandConditions'));
const ContactUsPage = lazy(() => import('./pages/ContactUsPage'));
const DocumentationPage = lazy(() => import('./pages/DocUrlPage'));

export default function Router() {
  const role = useSelector((data) => data.user.role);
  const isSystem = allTypeManager.includes(role);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <PrivateRoute> <DashboardLayout /> </PrivateRoute> ,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <PrivateRoute> <DashboardAppPage /> </PrivateRoute> },
        { path: 'jobs', element: <PrivateRoute> <JobPage /> </PrivateRoute> },
        { path: 'manage_users', element: isSystem ? <PrivateRoute> <ManageUsersPage /> </PrivateRoute> : <Navigate to="/dashboard/app" /> },
        { path: 'settings', element: <PrivateRoute> <SettingsPage /> </PrivateRoute> },
        { path: 'pay', element: <PrivateRoute> <LoadStripeContext><PaymentPage /></LoadStripeContext></PrivateRoute> },
        { path: 'profile', element: <PrivateRoute> <ProfilePage /> </PrivateRoute> },
        { path: 'payments', element: isSystem ? <PrivateRoute> <PaymentTransactionsAdmin /> </PrivateRoute> : <Navigate to="/dashboard/app" /> },
        { path: 'admin_settings', element: isSystem ? <PrivateRoute> <AdminSettingPage /> </PrivateRoute> : <Navigate to="/dashboard/app" /> },
        { path: 'manage_packages', element: isSystem ? <PrivateRoute> <ManagePackagesPage /> </PrivateRoute> : <Navigate to="/dashboard/app" /> },
        { path: 'workspaces', element: isSystem ? <PrivateRoute> <ManageWorkspacePage /> </PrivateRoute> : <Navigate to="/dashboard/app" /> },
        { path: 'manage_connections', element: <PrivateRoute> <ManageConnectionsPage /> </PrivateRoute> },
      ],
    },
    {
      path: 'login',
      element: <Suspense> <LoginPage /> </Suspense>,
    },
    {
      path: 'register_owner',
      element: <Suspense><LoadStripeContext> <RegisterOwnerPage /></LoadStripeContext></Suspense>,
    },
    {
      path: 'register',
      element: <Suspense><LoadStripeContext> <RegisterPage /></LoadStripeContext>  </Suspense>,
    },
    {
      path: 'forgot-password',
      element: <Suspense> <ResetPassword /> </Suspense>,
    },
    {
      path: 'reset-password',
      element: <Suspense> <CompleteReset /> </Suspense>,
    },
    {
      path: 'authcallback',
      element: <Suspense> <AuthCallbackPage /> </Suspense>,
    },
    {
      path: 'authcallbackzoom',
      element: <Suspense> <AuthCallbackZoomPage /> </Suspense>,
    },
    {
      path: 'privacy-policy',
      element: <Suspense> <PrivacyPolicyPage /> </Suspense>,
    },
    {
      path: 'terms-condition',
      element: <Suspense> <TermsAndCondition /> </Suspense>,
    },
    {
      path: 'contact-us',
      element: <Suspense> <ContactUsPage /> </Suspense>,
    },
    {
      path: 'usage-instruction',
      element: <Suspense> <DocumentationPage /> </Suspense>,
    },
    {
      path: 'subscribe',
      element: <Suspense> <LoadStripeContext><SubscriptionPage /></LoadStripeContext></Suspense>,
    },
    {
      element: <Suspense> <SimpleLayout /> </Suspense>,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Suspense> <Page404 /> </Suspense> },
        { path: '404_ws', element: <Suspense> <Page404WS /> </Suspense> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
